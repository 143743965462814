import 'select2'

$(document).on('turbolinks:load', function() {
  if ($("#venue_city_zipcode").length > 0) {
    $("#venue_city_zipcode").select2({
      minimumInputLength: 2,
      placeholder: '8000 Aarhus C',
      width: 'resolve',
      ajax: {
            url: "https://dawa.aws.dk/postnumre/autocomplete",
            dataType: "json",
            type: "GET",
            data: function (params) {

                var queryParameters = {
                    q: params.term
                }
                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            text: item.tekst,
                            id: item.tekst
                        }
                    })
                };
            }
        }
    });
  }

  if ($(".select2-select").length > 0) {
    $(".select2-select").select2({
      minimumInputLength: 2,
      placeholder: 'Snebærvej 4',
      width: 'resolve',
      ajax: {
            url: "https://dawa.aws.dk/autocomplete",
            dataType: "json",
            type: "GET",
            data: function (params) {

                var queryParameters = {
                    q: params.term,
                    fuzzy: true,
                    supplerendebynavn: false,
                    type: "adresse",
                    startfra: "adresse"
                }
                return queryParameters;
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            text: item.forslagstekst,
                            id: item.forslagstekst,
                            city: item.data.postnrnavn,
                            zipcode: item.data.postnr,
                            street: item.data.vejnavn + " " + item.data.husnr
                                // + " " + item.data.etage + item.data.dør
                            // latitude: item.data.y,
                            // longitude: item.data.x
                        }
                    })
                };
            }
        }
    });

    $('.select2-select').on('select2:select', function (e) {
        var data = e.params.data;
        $('.select2-city').val(data.city)
        $('.select2-zipcode').val(data.zipcode)
        $('.select2-street').val(data.street)
        // $('#venue_latitude').val(data.latitude)
        // $('#venue_longitude').val(data.longitude)
    });
  }
});