// Custom TailwindCSS modals for confirm dialogs

const Rails = require("@rails/ujs")

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm;

// Elements we want to listen to for data-confirm
const elements = ['a[data-confirm]', 'button[data-confirm]', 'input[type=submit][data-confirm]']

const createConfirmModal = (element) => {
  var id = 'confirm-modal-' + String(Math.random()).slice(2, -1);
  var confirm = element.dataset.confirm

  var content = `
    <div id="${id}" class="animated fadeIn fixed top-0 left-0 w-full h-full table z-50" style="background-color: rgba(0, 0, 0, 0.8);">
      <div class="table-cell align-middle">

        <div class="rounded-md overflow-hidden max-w-sm mx-auto">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  ${confirm}
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Er du sikker? Du kan ikke fortryde senere.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button data-behavior="commit" class="btn btn-red w-full block text-center sm:text-left sm:w-auto sm:ml-3">Bekræft</button>
            <button data-behavior="cancel" class="btn btn-white w-full block text-center sm:text-left sm:w-auto mt-3 sm:mt-0">Fortryd</button>
          </div>
        </div>
        
      </div>
    </div>

    
  `

  element.insertAdjacentHTML('afterend', content)

  var modal = element.nextElementSibling
  element.dataset.confirmModal = `#${id}`

  modal.addEventListener("keyup", (event) => {
    if(event.key === "Escape") {
      event.preventDefault()
      element.removeAttribute("data-confirm-modal")
      modal.remove()
    }
  })

  modal.querySelector("[data-behavior='cancel']").addEventListener("click", (event) => {
    event.preventDefault()
    element.removeAttribute("data-confirm-modal")
    modal.remove()
  })
  modal.querySelector("[data-behavior='commit']").addEventListener("click", (event) => {
    event.preventDefault()

    // Allow the confirm to go through
    Rails.confirm = () => { return true }

    // Click the link again
    element.click()

    // Remove the confirm attribute and modal
    element.removeAttribute("data-confirm-modal")
    Rails.confirm = old_confirm

    modal.remove()
  })

  modal.querySelector("[data-behavior='commit']").focus()
  return modal
}

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal;
}

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

  // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(', '), 'confirm', handleConfirm)
